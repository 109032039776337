import { IRecipe, RecipeCategoriesTypes } from '@/types/types'
import { Bevande } from '@/recipes/categories/bevande'
import { Cena } from '@/recipes/categories/cena'
import { Collazione } from '@/recipes/categories/collazione'
import { Dolce } from '@/recipes/categories/dolce'
import { Pranzo } from '@/recipes/categories/pranzo'
import { Snacks } from '@/recipes/categories/snacks'

export const Recipes: {[key in RecipeCategoriesTypes]: IRecipe[]} = {
  Collazione,
  Pranzo,
  Cena,
  Bevande,
  Dolce,
  Snacks
}
