import { IRecipe } from '@/types/types'

export const Pranzo: IRecipe[] = [
  {
    title: 'Piadina',
    description: 'Learn about the frameworks and languages such Vue and PHP',
    portion: 4,
    preparation: '<div><p>Disporre la farina di riso in una ciotola, unire un cucchiaio di olio evo, un pizzico di bicarbonato e uno di sale. Amalgamare il tutto con poca acqua e lavorare fino a ottenere una pastella cremosa. Cuocere un paio di cucchiai per volta in una padella antiaderente, 3 minuti per lato.</p></div>',
    preparationTime: '15 min',
    ingredients: [
      '300g di farina di riso',
      'Olio extravergine di oliva',
      'Sale',
      'Bicarbonato'
    ],
    image: {
      alt: 'piadina',
      width: '552',
      height: '842',
      srcset: 'oat-10.jpg',
      thumbnail: 'oat-10-1-sm.jpg',
      source: 'oat-10.jpg'
    },
    features: ['Gluten']
  },
  {
    title: 'Piadina',
    description: 'Learn about the frameworks and languages such Vue and PHP',
    portion: 4,
    preparation: '<div><p>Disporre la farina di riso in una ciotola, unire un cucchiaio di olio evo, un pizzico di bicarbonato e uno di sale. Amalgamare il tutto con poca acqua e lavorare fino a ottenere una pastella cremosa. Cuocere un paio di cucchiai per volta in una padella antiaderente, 3 minuti per lato.</p></div>',
    preparationTime: '15 min',
    ingredients: [
      '300g di farina di riso',
      'Olio extravergine di oliva',
      'Sale',
      'Bicarbonato'
    ],
    image: {
      alt: 'piadina',
      width: '552',
      height: '842',
      srcset: 'oat-10.jpg',
      thumbnail: 'oat-10-1-sm.jpg',
      source: 'oat-10.jpg'
    },
    features: ['Gluten']
  }
]
